// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Zentriert den Inhalt vertikal */
    justify-content: center; /* Zentriert den Inhalt horizontal */
}

.footer-content {
    display: flex;
    align-items: start;
}

.footer img {
    margin: 0 5px; /* Vereinfachte Schreibweise für oben und unten 0, links und rechts 5px */
    border-radius: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/footer.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,mBAAmB,EAAE,kCAAkC;IACvD,uBAAuB,EAAE,oCAAoC;AACjE;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,aAAa,EAAE,yEAAyE;IACxF,mBAAmB;AACvB","sourcesContent":[".footer {\n    margin-top: 10px;\n    display: flex;\n    flex-direction: column;\n    align-items: center; /* Zentriert den Inhalt vertikal */\n    justify-content: center; /* Zentriert den Inhalt horizontal */\n}\n\n.footer-content {\n    display: flex;\n    align-items: start;\n}\n\n.footer img {\n    margin: 0 5px; /* Vereinfachte Schreibweise für oben und unten 0, links und rechts 5px */\n    border-radius: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
