// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.context-field {
  border: 2px solid black;
  padding: 25px;
}

.product-field {
  border: 2px solid black;
  padding: 25px;
  margin-left: 10px;
  margin-right: 10px;
}

.documentation-field {
  border: 2px solid black;
  padding: 25px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.data-field {
  border: 2px solid black;
  padding: 25px;
}

.plot-and-download-field {
  border: 0.5px solid black;
  box-shadow: 5px 5px 10px grey;
  margin: 10px 0px 10px 0px;
}

@media only screen and (max-width: 1024px) {
  .main {
    display:flex;
  }

  .context-field, .product-field, .documentation-field, .data-field {
    background-color: white;
  }
  
}

@media only screen and (min-width: 1025px) {
  .main {
    display: flex;
    background-color: white;
    justify-content: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,6BAA6B;EAC7B,yBAAyB;AAC3B;;AAEA;EACE;IACE,YAAY;EACd;;EAEA;IACE,uBAAuB;EACzB;;AAEF;;AAEA;EACE;IACE,aAAa;IACb,uBAAuB;IACvB,uBAAuB;EACzB;AACF","sourcesContent":[".context-field {\n  border: 2px solid black;\n  padding: 25px;\n}\n\n.product-field {\n  border: 2px solid black;\n  padding: 25px;\n  margin-left: 10px;\n  margin-right: 10px;\n}\n\n.documentation-field {\n  border: 2px solid black;\n  padding: 25px;\n  margin-top: 10px;\n  margin-left: 10px;\n  margin-right: 10px;\n}\n\n.data-field {\n  border: 2px solid black;\n  padding: 25px;\n}\n\n.plot-and-download-field {\n  border: 0.5px solid black;\n  box-shadow: 5px 5px 10px grey;\n  margin: 10px 0px 10px 0px;\n}\n\n@media only screen and (max-width: 1024px) {\n  .main {\n    display:flex;\n  }\n\n  .context-field, .product-field, .documentation-field, .data-field {\n    background-color: white;\n  }\n  \n}\n\n@media only screen and (min-width: 1025px) {\n  .main {\n    display: flex;\n    background-color: white;\n    justify-content: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
