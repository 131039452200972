// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header{
    background-color: lightgrey;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px 10px 0px
}

#WINDIO {
    margin-right: 30px;
}

.header a {
    font-size: 25px;
    margin-left: 15px;
    text-decoration: none;
    color:  #1b2631 ;
}

.header a:visited {
    color:  #1b2631 ;
}

.icon {
    height: 20px;
    margin-right: 5px;
}

`, "",{"version":3,"sources":["webpack://./src/components/header.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB;AACJ;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,qBAAqB;IACrB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".header{\n    background-color: lightgrey;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin: 10px 0px 10px 0px\n}\n\n#WINDIO {\n    margin-right: 30px;\n}\n\n.header a {\n    font-size: 25px;\n    margin-left: 15px;\n    text-decoration: none;\n    color:  #1b2631 ;\n}\n\n.header a:visited {\n    color:  #1b2631 ;\n}\n\n.icon {\n    height: 20px;\n    margin-right: 5px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
